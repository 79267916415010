import React from "react"
import { Link } from 'gatsby'
import Logo from "../../../static/images/logo/logo.svg"
import Seo from "../../components/seo"
import BookHeader from "../../components/bookPageHeader"
import ReactBook from "../../../static/images/commonImg/reactbook.svg"
import ReactBookTS from "../../../static/images/commonImg/reactbook-ts.svg"
import ReactBook2 from "../../../static/images/commonImg/reactbook2.svg"
import ReactBook2TS from "../../../static/images/commonImg/reactbook2-ts.svg"
import NodeExpressBook from "../../../static/images/commonImg/nodebook-intro.svg"
import NodeExpressBook2 from "../../../static/images/commonImg/nodebook-advanced.svg"
//import NextBook2a from "../../../static/images/commonImg/nextbook2-1.svg"
import NextBook2aTS from "../../../static/images/commonImg/nextbook2-1ts.svg"
import MernBook from "../../../static/images/commonImg/mern-book-1.svg"
import GatsbyBook from "../../../static/images/commonImg/gatsbybook.svg"
import NextBook from "../../../static/images/commonImg/nextbook.svg"
import JamstackBook from  "../../../static/images/commonImg/jamstackbook.svg"
import vuebook from "../../../static/images/commonImg/vuebook.svg"
import NuxtBook from "../../../static/images/commonImg/nuxtbook.svg"
import JSBook from "../../../static/images/commonImg/jsbook.svg"
import ProxyBook from "../../../static/images/commonImg/proxyserver-book.png"
import NextFullAppFolder1 from "../../../static/images/commonImg/nextbook-app-fullstack-1.svg"
import TSmasterBook from "../../../static/images/commonImg/typescript-master-book.svg"
import ReactMasterBook from "../../../static/images/commonImg/react-master-book.svg" 
//import RegisterNewsLetterBody from "../../components/registerNewsLetter"
import NextServerActionsBook from "../../../static/images/commonImg/nextjs-server-actions.svg"
import BookForm from "../../components/bookForm"
import ReactMasterVideo from "../../../static/images/commonImg/react-master-video.svg"  
import TSmasterVideo from "../../../static/images/commonImg/typescript-master-video.svg"

//import AdNextFullstack from "./components/ad-next-fullstack-app-folder"

const generalData = {
    title: "Next.jsでつくるフルスタックアプリ（前編・後編／Next.jsバージョン15）",
    updated: "2025/3/16",
    urlToApp: "https://nextbook-fullstack-route-handlers.vercel.app",
    urlToCode1: "https://github.com/mod728/nextjs-book-fullstack-route-handlers",
    urlToCode2: "",
    reviewLink1: "https://www.amazon.co.jp/review/create-review/?ie=UTF8&channel=glance-detail&asin=B0DRHF4KGM",
    reviewLink2: "https://www.amazon.co.jp/review/create-review/?ie=UTF8&channel=glance-detail&asin=B0DRHRKVYV"
}

const booksData = [
    {oldPrice: "", newPrice: "", newRelease: "*2025年3月リリース", title: "React全部入り：Reactマスター", img: ReactMasterVideo, url: "https://monotein.base.ec/items/101764819", description: "Reactを『なんとなく』は使える人が、7時間で自信を持ってReactを書けるようになる動画コース！<br/><br/>JavaScriptの基礎の基礎、Reactの基本概念、発展的テクニック、そして最新のReactバージョン19まで全範囲をカバーした待望のマスターコース。<br/><br/>React入門に成功したビギナーが、たしかな理解とスキルを身につけて、自走できるようになるコース。<br/><br/>【動画数92本／計7時間26分】"},
    {oldPrice: "", newPrice: "", newRelease: "*2025年3月リリース", title: "React + TS徹底フォーカス：TypeScriptマスター with React", img: TSmasterVideo, url: "https://monotein.base.ec/items/101844836", description: "2日でTypeScript + Reactの苦手意識を捨てされる動画コース。<br/><br/>「TypeScriptをReactで使う」という点にフォーカスした貴重なハンズオン（作りながら学ぶ）形式の実用コース。<br/><br/>TypeScriptに苦手意識を持っていても、自信をもってReact + TypeScript開発を進められるようになります。<br/><br/>【動画数49本／計4時間37分】"},
]

const booksDataForSlider = [
    {newRelease: "", title: "", img: ReactBook, url: "https://www.amazon.co.jp/dp/B08XWMVX76", description: ""},
    {newRelease: "", title: "", img: ReactBookTS, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: ""},
    {newRelease: "", title: "", img: ReactBook2, url: "https://www.amazon.co.jp/dp/B09693ZJCV", description: ""},
    {newRelease: "", title: "", img: ReactBook2TS, url: "https://www.amazon.co.jp/dp/B09Q57Q9T1", description: ""},
    {newRelease: "", title: "", img: GatsbyBook, url: "https://www.amazon.co.jp/dp/B0BNQBDHFH", description: ""},
    {newRelease: "", title: "", img: JamstackBook, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: ""},
    {newRelease: "", title: "", img: NodeExpressBook, url: "https://www.amazon.co.jp/dp/B09HQZ1BW8", description: ""},
    {newRelease: "", title: "", img: NodeExpressBook2, url: "https://www.amazon.co.jp/dp/B09X56PSCQ", description: ""},
    {newRelease: "", title: "", img: JSBook, url: "https://www.amazon.co.jp/dp/B0C9C9SVFG", description: ""},
    {newRelease: "", title: "", img: vuebook, url: "https://www.amazon.co.jp/dp/B0B46QNTNL", description: ""},
    {newRelease: "", title: "", img: NuxtBook, url: "https://www.amazon.co.jp/dp/B0B5N8QJ2Y", description: ""},
    {newRelease: "", title: "", img: ProxyBook, url: "https://www.amazon.co.jp/dp/B0BBMQS28M", description: ""},
]

const links = [
    {
        chapterNum: "はじめに",
        data: [
            {name: "完成見本アプリ", url: "https://nextbook-fullstack-route-handlers.vercel.app"},
            {name: "JSON Formatter", url: "https://chromewebstore.google.com/detail/json-formatter/bcjindcccaagfpapjjmafapmmgkkhgoa"}
        ]
    },
    {
        chapterNum: 1,
        data: [
            {name: "全アイテムのデータページ", url: "https://nextbook-fullstack-route-handlers.vercel.app/api/item/readall"},
            {name: "個別のアイテムページ", url: "https://nextbook-fullstack-route-handlers.vercel.app/item/readsingle/65478ff981349fcd3162bf87"},
            {name: "個別のアイテムのデータページ", url: "https://nextbook-fullstack-route-handlers.vercel.app/api/item/readsingle/65478ff981349fcd3162bf87"},
            {name: "Node.js", url: "https://nodejs.org/en/download"},
            {name: "パッケージ情報", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/blob/master/package.json"},
            {name: "VS Code", url: "https://code.visualstudio.com/download"},
            {name: "Thunder Client", url: "https://marketplace.visualstudio.com/items?itemName=rangav.vscode-thunder-client"},
            {name: "エラー発生時の対処方法", url: "https://monotein.com/blog/how-to-find-and-fix-errors"},
        ]
    },
    {
        chapterNum: 2,
        data: [
            {name: "第2章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/tree/chapter2"},
        ]
    },
    {
        chapterNum: 3,
        data: [
            {name: "MongoDB", url: "https://www.mongodb.com"},
            {name: "第3章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/tree/chapter3"},
        ]
    },
    {
        chapterNum: 4,
        data: [
            {name: "JSON Web Token", url: "https://jwt.io"},
            {name: "第4章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/tree/chapter4"},
        ]
    },
    {
        chapterNum: 5,
        data: [
            {name: "Vercel", url: "https://vercel.com"},
            {name: "第5章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/tree/chapter5"},
        ]
    },
    {
        chapterNum: 6,
        data: [
            {name: "1つ目のアイテム", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/blob/master/item-data/1st-item.md"},
            {name: "2つ目のアイテム", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/blob/master/item-data/2nd-item.md"},
            {name: "3つ目のアイテム", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/blob/master/item-data/3rd-item.md"},
            {name: "4つ目のアイテム", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/blob/master/item-data/4th-item.md"},
            {name: "5つ目のアイテム", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/blob/master/item-data/5th-item.md"},
            {name: "6つ目のアイテム", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/blob/master/item-data/6th-item.md"},
            {name: "画像", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/tree/master/public"},
            {name: "ファビコン", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/blob/master/app/favicon.ico"},
            {name: "サーバーコンポーネントのわかりやすい説明", url: "https://monotein.com/blog/react-server-components-complete-guide"},
            {name: "サーバー／クライアントコンポーネントの解説（Next.js公式サイト）", url: "https://nextjs.org/docs/app/building-your-application/rendering/composition-patterns"},
            {name: "第6章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/tree/chapter6"},
        ]
    },
    {
        chapterNum: 7,
        data: [
            {name: "第7章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/tree/chapter7"},
        ]
    },
    {
        chapterNum: 8,
        data: [
            {name: "キャッシュの設定について（Next.js公式サイト）", url: "https://nextjs.org/docs/app/building-your-application/caching"},
            {name: "<Image>の設定について（Next.js公式サイト）", url: "https://nextjs.org/docs/app/api-reference/components/image#props"},
            {name: "CSS", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/blob/master/app/globals.css"},
            {name: "第8章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/tree/chapter8"},
            {name: "（参考情報）サーバーコンポーネントとReactバージョン19を使った編集・削除ページ開発方法", url: "https://monotein-books.netlify.app/next-fullstack-additional-rsc-v19-for-update-delete-pages"},
        ]
    },
    {
        chapterNum: 9,
        data: [
            {name: "第9章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers/tree/chapter9"},
        ]
    },
    {
        chapterNum: 10,
        data: [
            {name: "Cloudinary", url: "https://cloudinary.com"},
            {name: "第10章完了時点のソースコード", url: "https://github.com/mod728/nextjs-book-fullstack-route-handlers"},
        ]
    },
]

const NextFullstackServerActionsBookLinks = () => (
    <div className="container2">
        <Seo title={generalData.title} noindex={true} />
        <div><Link to="/blog"><img src={Logo} style={{width: "180px", margin: "auto", display: "block", paddingTop: "25px"}}/></Link></div>
        <div style={{textAlign: "center", marginTop: "16px"}}><a rel="noopener noreferrer" target="_blank" href="/blog">フロントエンド情報ブログ</a><span> || </span><a rel="noopener noreferrer" target="_blank" href="/business">著者について</a></div>
        <h3>『{generalData.title}』リンクページ</h3>
        <h4>更新日：<span>{generalData.updated}</span></h4>
        <BookHeader/>
        <ul>
            <h3 style={{backgroundColor: "#525252", display: "inline-block", color: "#fff", padding: "0 40px"}}>全般</h3>
       {/*      <li><span style={{color: "red"}}>*アイテムの作成／削除／編集のあと、データの変更が反映されない問題の解決策（後編・フロントエンド開発）</span>：<a rel="noopener noreferrer" target="_blank" href="https://monotein-books.netlify.app/nextjs-app-fullstack-page-not-update">https://monotein-books.netlify.app/nextjs-app-fullstack-page-not-update</a></li>
            <br/> */}
            {generalData.urlToApp && <li>見本アプリ：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToApp}>{generalData.urlToApp}</a></li>}
            <li>ソースコード：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode1}>{generalData.urlToCode1}</a></li>
          {/*   <li><span className="amazon-review">Amazonでレビューする（前編）</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink1}>{generalData.reviewLink1}</a></li>
            {generalData.reviewLink2 && 
                        <li><span className="amazon-review">Amazonでレビューする（後編）</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink2}>{generalData.reviewLink2}</a></li>
            } */}
            <br/>
            <li>*Reactサーバーコンポーネントについて：<a rel="noopener noreferrer" target="_blank" href="/blog/react-server-components-complete-guide">『Reactサーバーコンポーネントのわかりやすい説明』</a></li>
            <li>*本書で触れなかったNext.jsの便利な機能（フォント設定や404ページの設定など）について：<a rel="noopener noreferrer" target="_blank" href="/blog/nextjs-app-folder-new-things-list">『Next.js Appフォルダでの変更点を20個、時短で紹介』</a></li>
        </ul>

        <div className="book-wrapper">
            <div className="gridContainer">
                {booksData.map((x, index) => 
                    <div className="imgTextContainer" key={index}>
                        <a rel="noopener noreferrer" target="_blank" href={x.url}>
                            <img src={x.img} alt="cover"/>
                        </a>
                        <div>
                            {x.newRelease && <p className="new-release">{x.newRelease}</p>}
                            <br/>
                            {x.oldPrice && <p className="new-release-price">リリース記念特価（期間限定）<span>{x.oldPrice}円</span> ➡ {x.newPrice}円</p>}
                            <h3>『{x.title}』</h3>
                            {x.url.includes("monotein.base.ec") ? 
                                <a rel="noopener noreferrer" target="_blank" href={x.url}><p>→もっとくわしく見る</p></a>
                            :  
                                <a rel="noopener noreferrer" target="_blank" href={x.url}><p>Amazonで見る</p></a>
                            }
                            <p dangerouslySetInnerHTML={{ __html: x.description }}/>
                        </div>
                    </div>
                )}
            </div>
        </div>

        <ul>
            {links.map((x, index) => 
                <div key={index}>
                    <h3>{typeof x.chapterNum === "string" ? x.chapterNum : `第${x.chapterNum}章`}</h3>
                    {x.data.map((y, index) => 
                        <li key={index}>{y.name}： <a rel="noopener noreferrer" target="_blank" href={y.url}>{y.url}</a></li>
                    )}
                </div>
            )}
        </ul>
        <div style={{marginTop: "90px" }}></div>
        <BookForm bookTitle="NextjsFullAppFolder"/>
    </div>
)

export default NextFullstackServerActionsBookLinks
